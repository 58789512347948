import React from 'react';
import styled from 'styled-components';
import Card from '../components/Card'
export const ServicesStyles = styled.div`
font-size: var(--paragraphFontSize);
margin-left: 2vw;
margin-right: 2vw;
max-width: 450px;
margin-top: var(--headerHeight);
// text-align: center;
h1 {
  // grid-area: h1;
  grid-column: 1/2;
  grid-row: 1;
  text-align: center;
}
.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  }
`;

export default function ServicesPage() {
    return(
        <>
        <ServicesStyles>
          <h1>Services</h1>
          <div className="container">
            <Card>Consulting</Card>
            <Card>Special Projects</Card>
            <Card>Training</Card>
          </div>
        </ServicesStyles>
        </>
    )
}