import React from 'react';
import styled from 'styled-components';

const CardStyles = styled.div`
height: var(--footerHeight);
background: var(--cardColor);
margin: 15px;
border-radius: 4px;
text-align: center;
`;

export default function Card({children}) {
    return (
        <CardStyles>
            {children}
        </CardStyles>
    )
}